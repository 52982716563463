import React, {
  useState,
  useEffect,
  useContext,
  createContext,
  useMemo,
} from 'react'
import { AxiosResponse } from 'axios'
import { type } from 'os'
import Authentication from '../../utils/authentication'
import CapabilityAPI from '../../apis/capability.api'
import {
  getFeatures,
  ORG_SPLIT_FEATURE_NAMES,
  USER_SPLIT_FEATURE_NAMES,
} from '../../apis/feature.api'

export interface IAuthContext {
  isAuthed: boolean
  isOrgAdmin: boolean
  orgId: number | null
  isFirstRender: boolean
  loggedInUser: any
  checkAuth(): any
  capabilitiesByCategory: Record<string, string[]>
  createUserV2Enabled: boolean
  orgSwitcherFeatureEnabled: boolean
  selfProvisioningFeatureEnabled: boolean
}

export type UserInfo = {
  userId: number
  userName: string
  fullName: string
}

const authContext = createContext({} as IAuthContext)

const useProvideAuth = () => {
  const [isAuthed, setAuthed] = useState(false)
  const [isOrgAdmin, setOrgAdmin] = useState(false)
  const [orgId, setOrgId] = useState<number | null>(null)
  const [isFirstRender, setFirstRender] = useState(true)
  const [user, setUser] = useState<any>()
  const [error, setError] = useState()
  const [capabilitiesByCategory, setCapabilitiesByCategory] = useState<
    Record<string, string[]>
  >({})
  const [createUserV2Enabled, setCreateUserV2Enabled] = useState<boolean>(false)
  const [orgSwitcherFeatureEnabled, setOrgSwitcherFeatureEnabled] =
    useState<boolean>(false)
  const [selfProvisioningFeatureEnabled, setSelfProvisioningFeatureEnabled] =
    useState(false)

  const initializeCapabilities = async () => {
    const [capabilitiesPromise, categoriesPromise] = await Promise.all([
      CapabilityAPI.getAllCapabilities(),
      CapabilityAPI.getAllCapabilityCategories(),
    ])
    setCapabilitiesByCategory(
      CapabilityAPI.parseCapabilitiesByCategory(
        categoriesPromise.data,
        capabilitiesPromise.data
      )
    )
  }

  async function initializeFeatures() {
    const response = await getFeatures({
      userFeatures: [
        USER_SPLIT_FEATURE_NAMES.IAM_user_creation_V2,
        USER_SPLIT_FEATURE_NAMES.IAM_org_switcher,
      ],
      organizationFeatures: [ORG_SPLIT_FEATURE_NAMES.IAM_self_provision],
    })

    setCreateUserV2Enabled(
      response.userFeatures[USER_SPLIT_FEATURE_NAMES.IAM_user_creation_V2] ===
        'on'
    )

    setOrgSwitcherFeatureEnabled(
      response.userFeatures[USER_SPLIT_FEATURE_NAMES.IAM_org_switcher] === 'on'
    )

    setSelfProvisioningFeatureEnabled(
      response.organizationFeatures[
        ORG_SPLIT_FEATURE_NAMES.IAM_self_provision
      ] === 'on'
    )
  }

  useMemo(() => {
    initializeCapabilities()
  }, [])

  const checkAuth = async () => {
    try {
      const { userInfo, token } = await Authentication.getUMToken()
      setAuthed(true)
      setUser({
        userId: token.userId,
        userName: userInfo.userName,
        fullName: userInfo.fullName,
        organizations: userInfo.organizations,
        activeOrganization: userInfo.activeOrganization,
      })
      setOrgAdmin(token.capabilities.includes('ORGADMIN'))
      setOrgId(userInfo.activeOrganization.id)

      if (isFirstRender) {
        initializeFeatures()
      }

      setFirstRender(false)
      return { userInfo, token }
    } catch (err) {
      window.location.href = process.env.REACT_APP_LOGIN_URL || '/login'
      setFirstRender(false)
      return Promise.reject(err)
    }
  }

  useEffect(() => {
    checkAuth().catch(err => {
      setError(err)
    })

    // event listener to listen to local starage change event
    window.addEventListener('storage', localStorageUpdated)

    // cleanup callback function
    return () => {
      window.removeEventListener('storage', localStorageUpdated)
    }
  }, [])

  // method to handle /login redirection on multi tab logout at once
  const localStorageUpdated = () => {
    // set authentication to false if UM token and OIDC access token are not present in the local storage
    if (
      !localStorage.getItem('UMToken') &&
      !localStorage.getItem('accessToken')
    ) {
      setAuthed(false)
      setFirstRender(false)
    }
  }

  return {
    isAuthed,
    isOrgAdmin,
    orgId,
    isFirstRender,
    loggedInUser: user,
    checkAuth,
    capabilitiesByCategory,
    createUserV2Enabled,
    orgSwitcherFeatureEnabled,
    selfProvisioningFeatureEnabled,
  }
}

export const ProvideAuth = ({ children }: any) => {
  const auth = useProvideAuth()
  return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

export const useAuth = () => {
  return useContext(authContext)
}
