import { AxiosResponse } from 'axios'
import { makeUMRequest } from '../utils/request'

export interface FeatureEvaluationRequest {
  userFeatures?: string[]
  organizationFeatures?: string[]
  attributes?: Record<string, unknown>
}

export interface FeatureEvaluationResponse {
  userFeatures: Record<string, string>
  organizationFeatures: Record<string, string>
}

export enum USER_SPLIT_FEATURE_NAMES {
  IAM_user_creation_V2 = 'IAM_user_creation_V2',
  IAM_org_switcher = 'IAM_ORG_SWITCHER',
}

export enum ORG_SPLIT_FEATURE_NAMES {
  IAM_self_provision = 'IAM_SELF_PROVISION',
}

export async function getFeatures(
  request: FeatureEvaluationRequest
): Promise<FeatureEvaluationResponse> {
  const res: AxiosResponse = await makeUMRequest({
    method: 'POST',
    url: `feature`,
    data: request,
  })

  return res.data
}
