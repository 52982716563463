import { useHistory } from 'react-router-dom'

const useNavigation = () => {
  const history = useHistory()

  return {
    toOrganization: () => history.push(`/organization`),
    toOrganizationSSO: (id: number) => history.push(`/organization/${id}/sso`),
    toOrganizationOIDCSSO: (id: number) =>
      history.push(`/organization/${id}/sso/oidc`),
    toOrganizationSAMLSSO: (id: number) =>
      history.push(`/organization/${id}/sso/saml`),
    toLicense: (orgId: number, licenseId: number) =>
      history.push(`/organization/${orgId}/license/${licenseId}`),
    goBack: () => history.goBack(),
    toSearch: (query: string) =>
      history.push(`/search?q=${encodeURIComponent(query.trim())}`),
    toPath: (path: string) => history.push(path),
    toSelfProvisioning: (id: number) =>
      history.push(`/organization/${id}/provision`),
  }
}

export default useNavigation
